<template>
  <section>
    <TogglePanel
      :title="`${$tc('data_series', 1)}`"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_series_data_series"
    >
      <slot name="before"></slot>
      <DatasetSourceSelector
        ref="dss"
        @apply="applyNamedQuery"
        v-if="showDataSetSourceSelection"
      />
      <div
        class="title data-selection-box"
        v-if="!($refs.dss && $refs.dss.namedQueryEnabled)"
      >
        <div
          v-if="!dataSelector"
          class="clicable data-toggle"
          @click.stop.prevent="dataSelector = !dataSelector"
        >
          <i class="fa fa-caret-square-o-up" v-if="dataSelector"></i>
          <i class="fa fa-caret-square-o-down" v-else></i>
        </div>
        <div v-if="dataSelector">
          <ControlDataSelector
            v-model="dataId"
            :parser="uniqueDataList"
            :connectorId="connectorId"
            @connectorChanged="connectorId = $event"
            :dataListParser="dataListParser"
            :allowedTypes="['bool', 'float', 'int', 'string']"
            label="data_selector"
          >
            <template #extra_label>
              <div
                v-if="dataSelector"
                class="clicable data-toggle"
                @click.stop.prevent="dataSelector = !dataSelector"
              >
                <i class="fa fa-caret-square-o-up" v-if="dataSelector"></i>
                <i class="fa fa-caret-square-o-down" v-else></i>
              </div>
            </template>
          </ControlDataSelector>
          <div class="text-center" style="margin-bottom: 10px">
            <span
              class="btn btn-xs btn-primary"
              :class="dataId ? '' : 'disabled'"
              @click.stop.prevent="onAddData"
              style="margin: 0 10px 0 0"
            >
              {{ $tc("add_serie", 1) }}
            </span>
            <span class="btn btn-xs btn-default" @click.stop.prevent="onImport">
              {{ $t("titles.add_all_data") }}
            </span>
          </div>
        </div>
        <label
          v-else
          class="clicable text-primary no-select"
          @click.stop.prevent="dataSelector = !dataSelector"
        >
          {{ $t("add_serie") }}
        </label>
      </div>
      <div v-if="nDataList > 0" class="small" style="margin-top: 15px">
        {{ $t("dataset") }}
      </div>
      <draggable
        v-model="dataList"
        class="item"
        handle=".handle"
        :options="{disabled: hasOpenedSeries || namedQuery}"
      >
        <template v-for="(item, ix) in dataList">
          <ChartSerieForm
            ref="chartSerieForm"
            :value="item"
            :index="ix"
            :key="ix"
            :equipmentData="getEquipmentDataAt(ix)"
            :changeType="changeType"
            :checkable="checkable"
            :types="types"
            :symbol="symbol"
            :showApplyExpresson="showApplyExpresson"
            :showInterpolation="showInterpolation"
            :namedQuery="namedQuery"
            @removeData="onRemoveData"
            @changeType="onChangeType(ix, $event)"
            @check="onItemCheck(ix, $event)"
            @enable="onItemEnable(ix, $event)"
            @open="setSerieAsOpen(ix, true)"
            @close="setSerieAsOpen(ix, false)"
            class="handle"
          />
        </template>
      </draggable>
      <slot name="after"></slot>
      <div
        style="margin: 0px 0 10px 0; border-top: 1px solid lightgray"
        v-if="nDataList > 0"
      ></div>
    </TogglePanel>
    <!-- begin axis configuration -->
    <div v-if="nDataList > 0 && !pieOnly" style="">
      <slot name="before_axis"></slot>
      <TogglePanel
        :title="`${$tc('x_axle', 1)}`"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        persistent="toogle_chart_series_y_axle"
      >
        <ChartAxisForm
          v-model="xAxleConfig"
          propName="xAxle"
          :namedQuery="namedQuery"
        ></ChartAxisForm>
      </TogglePanel>
      <TogglePanel
        :title="`${$tc('y_axle', 1)}`"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        persistent="toogle_chart_series_y_axle"
        style="margin-bottom: 12px"
      >
        <ChartAxisForm
          v-model="yAxleConfig"
          propName="yAxle"
          :namedQuery="namedQuery"
        >
          <template #before>
            <div>
              <div class="form-group form-group-sm">
                <div>
                  <label for="">{{ $t("scale") }}</label>
                  <div
                    class="pull-right clicable"
                    @click.stop.prevent="yInverse = !yInverse"
                  >
                    <span>{{ $tc("titles.inverse", 2) }} </span>
                    <i
                      :class="
                        yInverse ? 'fa fa-check-square-o' : 'fa fa-square-o'
                      "
                    ></i>
                  </div>
                </div>
                <div class="input-group">
                  <div
                    class="inline-field"
                    style="width: 33%"
                    :title="$t('minimum')"
                  >
                    <label class="inline-field-label">{{
                      $t("minimum")
                    }}</label>
                    <input
                      type="number"
                      placeholder="auto"
                      class="form-control inner-field-input"
                      @input="yAxleProp('min', $event.target.value)"
                      :value="yAxleProp('min')"
                    />
                  </div>
                  <div
                    class="inline-field"
                    style="width: 33%"
                    :title="$t('maximum')"
                  >
                    <label class="inline-field-label">{{
                      $t("maximum")
                    }}</label>
                    <input
                      type="number"
                      placeholder="auto"
                      class="form-control inner-field-input"
                      @input="yAxleProp('max', $event.target.value)"
                      :value="yAxleProp('max')"
                    />
                  </div>
                  <div
                    class="inline-field"
                    style="width: 34%"
                    :title="$t('increment')"
                  >
                    <label class="inline-field-label">{{
                      $t("increment")
                    }}</label>
                    <input
                      type="number"
                      placeholder="auto"
                      class="form-control inner-field-input"
                      @input="yAxleProp('interval', $event.target.value)"
                      :value="yAxleProp('interval')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ChartAxisForm>
      </TogglePanel>
      <slot name="after_axis"></slot>
    </div>
    <!-- end axis configuration -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ChartSerieForm from "@/components/control-sidebar/property-editors/chart-serie-form.vue";
import Chart from "@/assets/dashboard/chart.json";
import DatasetSourceSelector from "@/components/control-sidebar/property-editors/dataset-source-selector.vue";
import ChartAxisForm from "@/components/control-sidebar/property-editors/chart-axis-form.vue";

import isEqual from "lodash/isEqual";

const defSerie = (type) => {
  return JSON.parse(JSON.stringify(Chart.seriesOptions[type || "line"]));
};

export {defSerie};

const dftCustomLabelFont = () => ({
  fontFamily: "Source Sans Pro",
  fontSize: "84%",
  fontStyle: "normal",
  fontWeight: "normal"
});

const dftCustomXAxisLabel = (namedQuery) => ({
  customFont: false,
  show: true,
  color: "#333",
  formatter: namedQuery ? "$value" : 'moment($value).format("DD/MM/YY HH:mm")',
  inside: false,
  rotation: 0,
  margin: 8,
  ...dftCustomLabelFont()
});

export default {
  name: "ChartSeriesForm",
  components: {
    TogglePanel,
    ControlDataSelector,
    ChartSerieForm,
    draggable,
    DatasetSourceSelector,
    ChartAxisForm
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    checkable: {
      type: Boolean,
      required: false,
      default: true
    },
    addAll: {
      type: Boolean,
      required: false,
      default: true
    },
    dataListParser: {
      type: Function,
      required: false,
      default: (lst) => lst
    },
    types: {
      type: Array,
      required: false,
      default: () => ["line", "bar", "pie"]
    },
    listName: {
      type: String,
      default: "data",
      required: false
    },
    symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    changeType: {
      type: Boolean,
      required: false,
      default: true
    },
    showApplyExpresson: {
      type: Boolean,
      requried: false
    },
    showInterpolation: {
      type: Boolean,
      required: false,
      default: true
    },
    showDataSetSourceSelection: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      curConnectorId: undefined,
      curDataId: undefined,
      panelOptions: null,
      dataSelector: true,
      opened: {},
      collapseExpression: false
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    dataList: {
      set(value) {
        this.panelOptions = this.panelOptions || {};
        this.$set(this.panelOptions, this.listName, value);
      },
      get() {
        return (this?.panelOptions || {})[this.listName] || [];
      }
    },
    dataIds() {
      return this.dataList.map(({data_id}) => data_id);
    },
    chartOptions() {
      return this.panelOptions?.chartOptions || null;
    },
    dataId: {
      set(vlr) {
        let digits = (vlr + "").match(/\d+/g);
        if (digits) {
          vlr = parseInt(digits.join(""));
        } else {
          vlr = "";
        }
        this.curDataId = vlr || "";
      },
      get() {
        return this.curDataId || "";
      }
    },
    connectorId: {
      set(value) {
        this.curConnectorId = value;
      },
      get() {
        return this.curConnectorId || this.referenceConnectorId || undefined;
      }
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || 0
      );
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    equipmentDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        (data) => data?.clp_id == this.connectorId
      );
    },
    hasOpenedSeries() {
      for (var i in this.opened) {
        if (this.opened[i]) return true;
      }
      return false;
    },
    yScaleExpressionEntry() {
      return {
        minimum: this.yAxleProp("min"),
        maximum: this.yAxleProp("max"),
        interval: this.yAxleProp("interval"),
        value: ""
      };
    },
    yInverse: {
      set(value) {
        this.yAxleProp("inverse", value);
      },
      get() {
        let vlr = this.yAxleProp("inverse");
        return vlr === "auto" || vlr === "" || vlr === undefined ? false : vlr;
      }
    },
    namedQuery() {
      return this?.panelOptions?.namedQuery || "";
    },
    namedQueryEnabled() {
      return !this.$refs.dss || this.$refs.dss.namedQueryEnabled;
    },
    nDataList() {
      return this?.dataList?.length || 0;
    },
    pieOnly() {
      return (this.dataList || []).every(
        ({chartOptions}) => chartOptions.type == "pie"
      );
    },
    xAxleConfig: {
      set(value) {
        if (!value) return;
        this.$set(this.panelOptions.chartOptions, "customXAxis", value.custom);
        // these properties are handled locally
        let axis = {...this.panelOptions.chartOptions.xAxis, ...value.data};
        this.$set(this.panelOptions.chartOptions, "xAxis", axis);
      },
      get() {
        return {
          propName: "xAxis",
          custom: this?.panelOptions?.chartOptions?.customXAxis,
          data: this?.panelOptions?.chartOptions?.xAxis || null
        };
      }
    },
    yAxleConfig: {
      set(value) {
        if (!value) return;
        if (value.custom) {
          // these properties are handled locally
          delete value.data.min;
          delete value.data.max;
          delete value.data.interval;
          delete value.data.inverse;
        }
        this.$set(this.panelOptions.chartOptions, "customYAxis", value.custom);
        let axis = {...this.panelOptions.chartOptions.yAxis, ...value.data};
        this.$set(this.panelOptions.chartOptions, "yAxis", axis);
      },
      get() {
        return {
          propName: "yAxis",
          custom: this?.panelOptions?.chartOptions?.customYAxis,
          data: this?.panelOptions?.chartOptions?.yAxis || null
        };
      }
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(n, o) {
        if (!isEqual(n, this.panelOptions)) {
          let panelOptions = JSON.parse(JSON.stringify(n));
          if (!o && n) {
            let missing = [];
            ((panelOptions || {})[this.listName] || []).forEach((data, ix) => {
              if (!data.chartOptions && data.data_id) {
                missing.push({id: data.data_id, ix: ix});
              }
            });
            if (missing.length) {
              this.panelOptions = panelOptions;
              missing.reverse().forEach((i) => {
                this.onRemoveData(i.ix);
              });
              missing.forEach((i) => {
                this.addData(i.id);
              });
              return;
            }
          }
          this.$set(this, "panelOptions", panelOptions);
        }
      }
    },
    panelOptions: {
      deep: true,
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit("input", val);
        }
      }
    }
  },
  methods: {
    yAxleProp(name, value) {
      let origin = this?.panelOptions?.chartOptions;
      // let origin = this?.chartOptions || this?.panelOptions?.chartOptions;
      if (!origin) {
        // history panel backward compatibility
        this.$set(this.panelOptions, "chartOptions", {});
        origin = this.panelOptions.chartOptions;
      }
      let yAxis = JSON.parse(JSON.stringify(origin?.yAxis || {}));
      var vlr = "";
      if (value === undefined) {
        if (name in yAxis) {
          vlr = yAxis[name];
        }
      } else {
        if (value === "" || value === "auto") {
          vlr = "";
        } else if (value === true || value === false) {
          vlr = value;
        } else {
          vlr = isNaN(Number(value)) ? "" : parseFloat(value);
        }
        yAxis[name] = vlr;
        this.$set(origin, "yAxis", yAxis);
      }
      return vlr === "auto" ? "" : vlr;
    },
    addData(dataId) {
      if (!dataId) return;
      // TODO: it seems that data selector is not adding to the global data list after connector changes
      let data = (this.equipmentDataList || []).find((i) => i.id == dataId);
      let chartOptions = defSerie("line");
      chartOptions.name = (data && data.name) || "";
      chartOptions.identity_embedded_app =
        (data && data.identity_embedded_app) || "";
      if ((data?.memory_type?.name || "").match(/(bool|coil)/gi) != null) {
        chartOptions.lineStyle.waveForm = "square";
      }
      chartOptions.itemStyle.color = Chart.colors[this.dataList.length || 0];
      const format = this.$root.$formatter.dataFormat(data);
      if (format.format_mask !== "text_list") {
        chartOptions.label = dftCustomXAxisLabel();
        chartOptions.label.show = false;
        chartOptions.label.formatter =
          this.$root.$formatter.dataFormatPattern(data);
      }
      let entry = {
        data_id: dataId,
        chartOptions: chartOptions,
        checked: true,
        enabled: true
      };
      this.dataList.push(entry);
      return entry;
    },
    onAddData() {
      this.addData(this.curDataId);
      if (this.curDataId && !this.hasSimulatedSamples(this.curDataId)) {
        this.$store.dispatch("history/simulate", this.curDataId);
      }
    },
    onImport() {
      this.dataListParser(this.equipmentDataList).forEach(({id}) => {
        this.addData(id);
      });
    },
    onRemoveData(ix) {
      if (this.namedQuery) return;
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        this.dataList.splice(ix, 1);
      }
    },
    onChangeType(ix, type) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let o = structuredClone(this.dataList[ix].chartOptions);
        let n = defSerie(type);
        if (o.type != n.type) {
          n.name = o.name ?? n.name;
          let nPosition = n?.label?.position;
          n.label = o.label ?? n.label;
          if (n.label) {
            n.label.position = nPosition ?? n.label.position;
          }
          n.identity_embedded_app =
            o.identity_embedded_app ?? n.identity_embedded_app;
          n.itemStyle.color = o.itemStyle.color ?? n.itemStyle.color;
          n.itemStyle.expression =
            o.itemStyle.expression ?? n.itemStyle.expression;
          this.$set(this.dataList[ix], "chartOptions", n);
          if (n.type == "pie" && this.namedQuery) {
            let lst = (this.dataList || [])
              .filter(({chartOptions}) => chartOptions.type == "pie")
              .map(({chartOptions}) => chartOptions);
            let radius = Math.floor(
              Number(this.$utils.onlyNumbers(n.radius)) * (1 / lst.length)
            );
            let x = 100 / (lst.length + 1);
            lst.forEach((cfg) => {
              cfg.radius = `${radius}%`;
              cfg.center[0] = `${Math.floor(x)}%`;
              x = x + 100 / (lst.length + 1);
            });
          }
        }
      }
    },
    onItemCheck(ix, value) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        this.$set(this.dataList[ix], "checked", value);
      }
    },
    onItemEnable(ix, value) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        // this.$set(this.dataList[ix].chartOptions.itemStyle, "enabled", value);
        // this.$set(this.dataList[ix], "enabled", value);
        this.dataList[ix].chartOptions.itemStyle.enabled = value;
        this.dataList[ix].enabled = value;
      }
    },
    getEquipmentDataAt(ix) {
      if (ix >= 0 && ix <= (this.dataList || []).length - 1) {
        let id = this.dataList[ix].data_id;
        return (
          (this.$store.getters["dashboard/dataList"] || []).find(
            (data) => data.id == id
          ) || null
        );
      }
      return null;
    },
    uniqueDataList(items) {
      let lst = this.dataListParser(items);
      return lst.filter(({id}) => this.dataIds.indexOf(id) == -1);
    },
    setSerieAsOpen(ix, value) {
      this.$set(this.opened, ix, value);
      if (value) {
        this.activeItem = ix;
        (this.$refs.chartSerieForm || []).forEach((item) => {
          if (item.index != this.activeItem && !item.collapsed) {
            item.toggle();
          }
        });
      }
    },
    hasSimulatedSamples(dataId) {
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[dataId] || {})?.samples || []).length > 0;
    },
    applyNamedQuery(entry) {
      this.$nextTick(() => {
        this.dataList = [];
        this.customXAxis = false;
        if (!entry?.dataList?.length) return;
        (entry.dataList || []).forEach(
          ({data_id, columnName, columnFormat}) => {
            let dataSerie = this.addData(data_id);
            if (dataSerie?.chartOptions?.name) {
              if (columnName) dataSerie.chartOptions.name = columnName;
              if (columnFormat) {
                // dataSerie.chartOptions.label =
                //   dataSerie.chartOptions.label || {};
                // dataSerie.chartOptions.label.formatter = columnFormat;
                let label = dftCustomXAxisLabel(entry.name);
                label.show = false;
                label.formatter = columnFormat;
                this.$set(dataSerie.chartOptions, "label", label);
              }
            }
          }
        );
      });
    }
  },
  created() {
    // The code below avoid backward compatibilities issues
    if (this?.panelOptions?.chartOptions) {
      for (var p in this.yScaleExpressionEntry || {}) {
        if (this.yScaleExpressionEntry[p] !== "") {
          this.panelOptions.chartOptions.customYAxis = true;
          break;
        }
      }
    }
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.tab-content {
  background: white;
}

.inner-panel {
  padding-top: 10px;
}

.inner-panel > * {
  margin-bottom: 10px;
}

.clicable:hover {
  background-color: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.data-selection-box {
  position: relative;
  /* background-color: whitesmoke; */
  padding: 5px;
  margin: 5px -5px 5px -5px;
}

.title {
  font-size: 10pt;
}

.data-toggle-link {
  padding: 2px;
}

.data-toggle {
  float: right;
}

.content-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-field {
  position: relative;
  display: inline-block;
}

.inline-field > input {
  z-index: 0;
  background-color: transparent;
  text-align: center;
  padding: 8px 0 0 0;
}

.inline-field > label {
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1;
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.custom-format-form {
  margin: 0 0 15px 0;
}

.expression-box {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2px 6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
